
const auth = {
  state: {
    login: {
      sessionId: false,
      customerId: false,
      expire: 0,
      lastLogin:null,
    },
  },
  mutations: {

    SET_LOGIN_INFO: (state, login) => {
      if (process.env.NODE_ENV === 'development') {
        console.log(login)
      }
      state.login.sessionId  = login.sessionId                     // ログイントークン
      state.login.customerId   = login.customerId                      // ユーザー名
      state.login.expire = Math.floor(1000 * login.expireUnixTime) // APIからUNIXタイム(秒)で有効期限が返ってくるものとし、ミリ秒に変換しておく
      state.login.lastLogin = login.lastLogin;
    }, 
    RESET_AUTH_DATA: (state) => {
        state.login.sessionId  = false             
        state.login.customerId   = false                
        state.login.expire = 0 
        state.login.lastLogin = null;
    },
   
  },
  actions: {
    setLoginInfo({ commit }, login) {
      commit("SET_LOGIN_INFO", login)
    },
    resetAuthData({ commit }) {
      commit("RESET_AUTH_DATA")
    },  
  }
}

export default auth