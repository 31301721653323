<template>
  <v-app>
    <v-layout>
      <v-app-bar v-if="this.$store.state.auth.login.sessionId" app color="primary" clippedLeft>
        <router-link color="primary" :to="`/project`" style="text-decoration: none" class="text-h6">
          <v-img src="./assets/logo_white.webp" max-height="100%" max-width="160"></v-img>
        </router-link>
        <v-spacer></v-spacer>
        <p class="mb-0 mr-2" v-if="this.$store.state.user_model.userData.userId &&
          !$vuetify.breakpoint.xs
          "></p>

        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-avatar color="#cfe3f6" size="44">
                <span class="text-h6" style="color: #000000DE;">{{ userIdInitial }}</span>
              </v-avatar>
            </v-btn>
          </template>
          <v-card color="white">
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar color="#cfe3f6" size="44">
                  <span class="text-h6">{{ userIdInitial }}</span>
                </v-avatar>
                <p class="text-caption mt-1 mx-4">{{ this.$store.state.user_model.userData.userId }}</p>
                <v-divider class="my-3"></v-divider>
                <v-btn :to="`/project`" depressed rounded text>
                  <v-icon left>mdi-folder</v-icon>
                  プロジェクト
                </v-btn>
                <v-divider class="my-3"></v-divider>
                <v-btn :to="`/account`" depressed rounded text>
                  <v-icon left>mdi-account</v-icon>
                  アカウント
                </v-btn>
                <v-divider class="my-3"></v-divider>
                <v-btn :to="`/support`" depressed rounded text>
                  <v-icon left>mdi-mail</v-icon>
                  お問合せ
                </v-btn>
                <v-divider class="my-3"></v-divider>
                <v-btn @click="logout()" depressed rounded text>
                  <v-icon left>mdi-logout</v-icon>
                  サインアウト
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </v-app-bar>

      <v-main>
        <router-view />
      </v-main>
    </v-layout>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: "App",

  data() {
    return {
      drawer: this.$vuetify.breakpoint.lgAndUp,
      env: process.env.VUE_APP_ENV,
      loading: this.$store.state.user_model.topLoading,

      loadedList: [],
      links: [
        { title: "プロジェクト", link: "/project", icon: "folder" },
        { title: "アカウント", link: "/account", icon: "account" },
        { title: "お問合せ", link: "/support", icon: "mail" },
      ],
    };
  },

  created() {
    if (this.$store.state.auth.login.sessionId) {
      this.$store.state.user_model.topLoading = true;
      this.$store
        .dispatch("setUserInfo", this.$store.state.auth.login)
        .then(() => {
          this.loadedList.push("user");
        });
    }
  },

  mounted() {
    window.addEventListener('beforeunload', () => {
      this.atReload();
    });
  },

  watch: {
    loadedList: {
      handler() {
        if (Number(this.$store.state.user_model.userData.status) !== -1) {
          if (this.loadedList.length === 1) {
            this.$store.state.user_model.topLoading = false;
          }
        } else {
          this.logout();
        }
      },
      deep: true,
    },
  },

  computed: {
    userIdInitial() {
      const userId = this.$store.state.user_model.userData.userId;
      if (userId) {
        return userId.charAt(0).toUpperCase();
      } else {
        return '';
      }
    },
  },

  methods: {
    atReload() {
      if (Number(this.$store.state.user_model.userData.status) !== -1) {
        this.$store.dispatch("resetUserDataAtReload");
      } else {
        this.logout();
      }
    },

    async logout() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_URL}logout`, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            SessionId: this.$store.state.auth.login.sessionId,
          },
        });

        if (response.data) {
          if (process.env.NODE_ENV === 'development') {
            console.log("Logout");
          }
        }

        await Promise.all([
          this.$store.dispatch("resetUserData"),
          this.$store.dispatch("resetAuthData"),
        ]);

        this.$router.go({ path: this.$router.currentRoute.path, force: true });
        this.$store.reset();
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>


<style lang="scss">
.snack__border {
  border: 3px solid red;
  background: white;
}

.login-card {
  width: 100%;
  max-width: 400px;
  margin: auto;
  z-index: 2000;
}

.login-backgroud-img {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: url("~@/assets/background.webp") no-repeat center center;
  background-size: cover;
}

.dropzone {
    width: 100%;
    height: calc(50vh - 160px);
    border: 2px dashed gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.hovered:hover {
  background-color: #cfd3d8;
}

.dropzone-active {
    background-color: lightgray;
}

.active_list{
  background: rgba(255, 255, 255, 0.266);
  color: #87b5fb !important;;
}

.v-main__wrap {
  background-color: #f6fafd;
  color: #384048 !important;
}

.v-progress-linear__buffer {
  background-color: white!important;
}

.v-card__title{
  color: #384048 !important;
}

.v-icon{
  color: #384048;
}

.v-btn{
  color: #384048;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

th{
  text-align: center;
}
td{
  text-align: center;
}
.v-chip:hover {
  color: #384048;
  border-color: #384048;
}
.v-slide-group__content {
  background-color: #F4F9FF;
}
.v-data-table > .v-data-table__wrapper > table {
  background-color: #FFFFFF;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td.text-start{
  padding: 0;
}

.v-application {
  font-family: "Noto Sans JP", sans-serif !important;
  color: #384048 !important;

  .text-h1 {
    font-family: "Noto Sans JP", sans-serif !important;
    color: #384048 !important;
  }
  .text-h2 {
    font-family: "Noto Sans JP", sans-serif !important;
    color: #384048 !important;
  }

  .text-h3 {
    font-family: "Noto Sans JP", sans-serif !important;
    color: #384048 !important;
  }

  .text-h4 {
    font-family: "Noto Sans JP", sans-serif !important;
    color: #384048 !important;
  }

  .text-h5 {
    font-family: "Noto Sans JP", sans-serif !important;
    color: #384048 !important;
  }

  .text-h6 {
    font-family: "Noto Sans JP", sans-serif !important;
    color: #384048;
  }

  .body-1 {
    font-family: "Noto Sans JP", sans-serif !important;
    color: #384048 !important;
  }

  .body-2 {
    font-family: "Noto Sans JP", sans-serif !important;
    color: #384048 !important;
  }

  .caption {
    font-family: "Noto Sans JP", sans-serif !important;
    color: #384048 !important;
  }

  .text-start {
    background-color:#cfe3f6!important;
    font-size: 12px !important;
    color: #384048 !important;
    text-align:center!important;
  }

  .text-truncate{
    display: none !important;
  }
}
.confusion-header .text-start{
  background-color:#f9f9fa !important;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.confusion-header span{
  font-size: 14px;
  color: #444444;
}
.confusion-cell {
  min-height: 8vw;
  max-height: 16vw;
  font-size: 14px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #444444;
  font-weight:bold;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.confusion-cell.text-start {
  padding: 0!important;
}
</style>