import Vue from "vue"
import Router from "vue-router"

import store from "@/store/index.js"

const Login = () => import('@/views/Login.vue');
const Account = () => import('@/views/Account.vue');
const CreateAccount = () => import('@/views/CreateAccount.vue');
const CreateAccountConfirm = () => import('@/views/CreateAccountConfirm.vue');
const ResetPassword = () => import('@/views/ResetPassword.vue');
const ChangePassword = () => import('@/views/ChangePassword.vue');
const Reset = () => import('@/views/Reset.vue');
const Project = () => import('@/views/Project.vue'); 
const ProjectDetail = () => import('@/views/ProjectDetail.vue');
const Withdraw = () => import('@/views/Withdraw.vue');
const Unlock = () => import('@/views/Unlock.vue');
const Support = () => import('@/views/Support.vue');
const NotFound = () => import('@/views/NotFound.vue');




Vue.use(Router)
const router = new Router({
  mode: "history", // URLはハッシュ形式「#～～」ではなく「/～～」とする
  linkActiveClass: "active",
  routes:[
    {path: '/',name: 'home',component: Project},
    {path: '/login',name: 'login',component: Login },
    {path: '/account',name: 'account',component: Account },
    {path: '/create_account',name: 'create_account',component: CreateAccount },
    {path: '/create_account/confirm',name: 'create_account_confirm',component: CreateAccountConfirm },
    {path: '/reset_password',name: 'reset_password',component: ResetPassword },
    {path: '/change_password', name: 'change_password', component: ChangePassword },
    {path: '/reset',name: 'reset',component: Reset },
    {
      path: '/project',
      name: 'projects',
      component: Project,
      beforeEnter: (to, from, next) => {
        // セッションストレージをクリア
        sessionStorage.removeItem('data');
        next();
      },
    }, 
    {
      path: '/project/:project_id',
      name: 'projects_detail',
      component: ProjectDetail,
      children: [
        {
          path: 'tab/:tabId',
          name: 'tab',
          props: true
        }
      ] }, 
    {path: '/withdraw',name: 'withdraw',component: Withdraw }, 
    {path: '/unlock',name: 'unlock',component: Unlock },
    {path: '/support',name: 'support',component: Support },
    { path: '*', name: 'notfound',component: NotFound },
  ]
})

var no_login_url= [
  '/create_account',
  '/create_account/confirm',
  '/reset_password',
  '/reset',
  '/sample', 
  '/unlock',
  '/logo.png'

]
// router gards
router.beforeEach((to,from,next) => {
  // トークンが存在、かつログイン有効期限を過ぎてない場合、またはログイン画面の場合
  if(to.matched.some(page => {return (no_login_url.includes(page.path))})){
    next()
  }
  else if ((store.state.auth.login.sessionId && store.state.auth.login.customerId && store.state.auth.login.expire > (new Date()).getTime()) && to.matched.some(page => {return (page.path === '/login')}) ) {
    next({name: 'home'})
  }
  else if((store.state.auth.login.sessionId && store.state.auth.login.customerId && store.state.auth.login.expire > (new Date()).getTime())){
      next()
  }
  else if(to.matched.some(page => {return (page.path === '/login')})) { 
    next() 
  } else {
    // ログイン画面に飛ばす。ログイン後に元の画面に戻れるよう、backuriパラメーターにリダイレクト前のURLを入れる
    next({path: '/login', query: {backuri: "/"}})
  }
})

router.afterEach(() => {
})

export default router