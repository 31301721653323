
import axios from "axios"
import { decodeTime } from "ulidx";

const tmp = {
    userId: null,
    status: null,
    lastLogin:null,
    isLoginNotification:false,
  };


const user_model = {
  state: {
    read_id_list:[],
    userData: {
      userId: null,
      status: null,

      lastLogin:null,
    },
    project:[],
    dataset:[],
    projectName: null,
    projectClassType: null,
    auth:false,
    topLoading:false,
  },
  mutations: {

    SET_USER_INFO: (state,login) => {
      state.userData = login;
      state.userData.userId = login.userId;
      state.userData.status = login.status;
      state.userData.lastLogin = login.lastLogin;
      state.auth = true;
      state.userData.isLoginNotification = login.isLoginNotification;
    }, 

    SET_PROJECT_INFO: (state,data) => {
      state.project = data;
    }, 

    SET_DATASET_INFO: (state,data) => {
      state.dataset = data;
    }, 

    setProjectName(state, name) {
      state.projectName = name;
    },
    setClassType(state, type) {
      state.projectClassType = type;
    },

    CHANGE_LOGIN_NOTIFICATION: (state,isLoginNotification) => {
      state.userData.isLoginNotification = isLoginNotification;
    }, 

    RESET_USER_DATA: (state) => {
      if (process.env.NODE_ENV === 'development') {
        console.log("DELETE USER DATA")
      }
      state.userData = tmp;
      state.project = [],
      state.auth = false;
    },
    
    RESET_USER_DATA_AT_RELOAD: (state) => {
      if (process.env.NODE_ENV === 'development') {
        console.log("DELETE USER DATA")
      }
      state.userData["userId"] = null;
      state.project = [],
      state.free_content = [];
    },

    ADD_READ_LIST: (state,id) => {
      if (process.env.NODE_ENV === 'development') {
        console.log("ADD READ")
      }
      state.read_id_list.push(id);
    },

    ADD_PROJECT: (state,data) => {
      if (process.env.NODE_ENV === 'development') {
        console.log("ADD PROJECT")
      }
      state.project.push(data);
    },

    ADD_DATASET: (state,data) => {
      if (process.env.NODE_ENV === 'development') {
        console.log("ADD DATASET")
      }
      state.dataset.push(data);
    },
  },
  actions: {
    async setUserInfo({ commit }, login) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_URL}${login.customerId}/account`, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": login.sessionId
          }
        });

        let setData = response.data.results;
        setData.lastLogin = login.lastLogin;
        commit("SET_USER_INFO", setData);
      } catch (reason) {
        console.log(reason);
      }
    },

    async setProjectInfo({ commit }, login) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_URL}${login.customerId}/project`, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": login.sessionId
          }
        });

        const setData = response.data.results;
        for (let i = 0; i < setData.length; i++) {
          const id = setData[i].ProjectId;
          const unix = decodeTime(id); // You need to implement decodeTime function
          setData[i].created = new Date(unix);
        }

        commit(`SET_PROJECT_INFO`, setData);
      } catch (error) {
        console.log(error);
      }
    },

    async changeLoginNotification({ commit }, login) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_URL}${login.customerId}/account`,
          {
            "IsLoginNotification": !this.state.user_model.userData.isLoginNotification
          },
          {
            headers: {
              "X-Api-Key": process.env.VUE_APP_KEY,
              "SessionId": login.sessionId,
              "Content-Type": "application/json"
            }
          }
        );

        if (process.env.NODE_ENV === 'development') {
          console.log(response);
        }

        commit(`CHANGE_LOGIN_NOTIFICATION`, !this.state.user_model.userData.isLoginNotification);
      } catch (error) {
        console.log(error);
      }
    },

    resetUserData({ commit }) {
      commit("RESET_USER_DATA")
    },

    resetUserDataATReload({ commit }) {
      commit("RESET_USER_DATA_AT_RELOAD")
    },

    addReadList({ commit },id) {
      commit("ADD_READ_LIST",id)
    },

    addProject({ commit },data) {
      commit("ADD_PROJECT",data)
    },

    addDataset({ commit },data) {
      commit("ADD_DATASET",data)
    },

  }
}

export default user_model