import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);


export default new Vuetify({
    theme: {
      themes: {
        light: {
          sub_theme:"#87B5FB",
          link: "#1976d2",
        },
      },
    },
    icons: {
      iconfont: 'mdiSvg', 
    },
});
